import React, { Component } from 'react';
import Images from './components/Images';
import './App.css';
var config = [
  {
    hostname:['localhoset','finland.net','www.finland.net'],
    keyword:['finland+nature'],
    title:'Finland.Net',
    subtitle:'Land of Super Natural',
  },
  {
    hostname:['localhost','england.org','www.england.org'],
    keyword:['england+nature'],
    title:'England.Org',
    subtitle:'Land of Lords',
    // default:true
  },
  {
    hostname:['localhost','germany.org','www.germany.org'],
    keyword:['germany'],
    title:'Germany.Org',
    subtitle:'Land of Beer',
    // default:true
  },
  {
    hostname:['localhost','spain.org','www.spain.org'],
    keyword:['spain'],
    title:'Spain.Org',
    subtitle:'Land of Tradition',
    default:true
  },
  {
    hostname:['localhost','istanbul.org','www.istanbul.org'],
    keyword:['istanbul'],
    title:'Istanbul.Org',
    subtitle:'City of Tradition',
    default:true
  },
  {
    hostname:['localhost','antalya.org','www.antalya.org'],
    keyword:['antalya'],
    title:'Antalya.Org',
    subtitle:'City of Sun',
    ga_id:'UA-38756672-6',
    default:true
  },


]

class App extends Component {
  render() {
    var { hostname } = window.location
    var defaultConfig 
    var siteConfig
    for(var c of config) {
      if (c.default) defaultConfig=c
      if (c.hostname.includes(hostname.toLowerCase())) {
        siteConfig = c
      }
    }
    if (!defaultConfig) return (
      <div>No default config</div>
    )
    
    if (!siteConfig) {
      console.log(`${hostname} site config not found, defaulting`)
      siteConfig = defaultConfig
    }
    // console.log(siteConfig)




    return (
      <div id='root'>
        <div className='hero is-fullheight is-bold is-info'>
          <div className='hero-body'>
            <div className='container'>
              <div className='header content'>
                <h2 className='subtitle is-6'>{siteConfig.subtitle}</h2>
                <h1 className='title is-1'>
                  {siteConfig.title}
                </h1>
              </div>

              <Images siteConfig={siteConfig}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
