import React, { Component } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import Image from './Image';

export class Images extends Component {
  state = {
    images: [],
    count: 30,
    start: 1
  };
  

  
  componentDidMount() {
    const { count, start } = this.state;
    axios
      .get(`/api/photos?count=${count}&start=${start}&kw=${this.props.siteConfig.keyword[0]}`)
      .then(res => {
        // console.log(`res`,res) 
        this.setState({ start: this.state.start + 1,images: res.data })
    });
  

  }

  fetchImages = () => {
    console.log(`fetchImages`,this.state.start,this.state.images.length)
    const { count, start } = this.state;
    var that = this
    // this.setState({  });
    axios
      .get(`/api/photos?count=${count}&start=${start}&kw=${this.props.siteConfig.keyword[0]}`)
      .then(res => {
        console.log(`fetchImages`,res) 
        that.setState({ 
          images: that.state.images.concat(res.data),
          start: this.state.start + 1
        })
        }
        )
      ;
  };

  render() {
    console.log(this.props.siteConfig)
    // console.log(this.state.images)
    // console.log(this.state.start,this.state.count)
    return (
      <div className='images'>
        <InfiniteScroll
          dataLength={this.state.images.length}
          next={this.fetchImages}
          hasMore={true}
          loader={<h4>Loading more...</h4>}
        >
          {this.state.images.map(image => (
            <Image key={image.id} image={image} />
          ))}
        </InfiniteScroll>
      </div>
    );
  }
}

export default Images;
